import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import './ListaDeTransferencias.css';

function ModalTransferencia({ show, handleClose, onTransferenciaCreada, API_URL, SECRET }) {
  const [cuentas, setCuentas] = useState([]); // Inicializamos cuentas como un array vacío
  const [cvuDest, setCvuDest] = useState('');
  const [amount, setAmount] = useState('');
  const [concept, setConcept] = useState('VAR');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (show) {
      fetchCuentas();
    }
  }, [show]);

  const fetchCuentas = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${API_URL}/public/ListAccounts`, {
        headers: {
          'SERVICIOS-URBANOS-TOKEN': token,
        },
      });

      if (response.data.ok) {
        // Verificamos si msg existe y si tiene la estructura esperada
        const cuentasData = response.data.msg && Array.isArray(response.data.msg) ? response.data.msg : [];
        setCuentas(cuentasData);
      } else {
        setError('Error al obtener cuentas');
      }
    } catch (err) {
      setError('Error al obtener cuentas');
     // console.error('Error:', err.response || err.message);
    }
  };

  const handleTransfer = async () => {
    const token = localStorage.getItem('token');
    setError('');
    setSuccess(false);
    
    try {
      const response = await axios.post(
        `${API_URL}/public/Transfer`,
        {
          cvuDest,
          amount: parseFloat(amount),
          concept,
        },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
            'Secret': SECRET,
          },
        }
      );
      
      if (response.data.ok) {
        setSuccess(true);
        onTransferenciaCreada();
      } else {
        setError(response.data.msg || 'Error al realizar la transferencia');
      }
    } catch (err) {
      setError('Error al realizar la transferencia');
     // console.error('Error al transferir:', err.response || err.message);
    }
  };

  const isDisabled = !cvuDest || !amount || parseFloat(amount) <= 0;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Hacer Transferencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Transferencia realizada con éxito</Alert>}
        
        <Form>
          <Form.Group controlId="cvuDest">
            <Form.Label>Cuenta de destino</Form.Label>
            <Form.Control 
              as="select" 
              value={cvuDest} 
              onChange={(e) => setCvuDest(e.target.value)}
            >
              <option value="">Seleccione cuenta de destino</option>
              {cuentas?.length > 0 && cuentas.flatMap((cuenta) => 
                cuenta.accounts && Array.isArray(cuenta.accounts) ? 
                cuenta.accounts.map((account, index) => (
                  <option key={index} value={account.cvu}>
                    {account.label}
                  </option>
                )) : null
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="amount" className="mt-3">
            <Form.Label>Monto</Form.Label>
            <Form.Control 
              type="number" 
              placeholder="Ingrese el monto" 
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="concept" className="mt-3">
            <Form.Label>Concepto</Form.Label>
            <Form.Control 
              as="select" 
              value={concept} 
              onChange={(e) => setConcept(e.target.value)}
            >
              <option value="ALQ">Alquiler</option>
              <option value="CUO">Cuota</option>
              <option value="EXP">Expensas</option>
              <option value="FAC">Factura</option>
              <option value="PRE">Préstamo</option>
              <option value="SEG">Seguro</option>
              <option value="HON">Honorarios</option>
              <option value="HAB">Haberes</option>
              <option value="VAR">Varios</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn-cerrar" onClick={handleClose}>
          Cerrar
        </Button>
        <Button 
          variant="primary" 
          className="agregar-button" 
          onClick={handleTransfer} 
          disabled={isDisabled}
        >
          Hacer Transferencia
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalTransferencia;
