// useFetchListaDeCajas.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchCajas = (idBranch) => {
  const [cajas, setCajas] = useState([]);
  const [nombreSucursal, setNombreSucursal] = useState('');
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false); 
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1); // Estado para el total de páginas
  
  const fetchCajas = async (fechaInicio = '', fechaFin = '', page = 1) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Token no disponible.');
      setLoading(false);
      return;
    }
    
    const API_URL = process.env.REACT_APP_URL;

    setIsFetching(true);  
    setError(null); // Limpiar error antes de la nueva consulta
    try {
      const body = {
        idBranches: idBranch,
        page: page
      };

      // Solo incluir fechas si están definidas
      if (fechaInicio) body.inicio = fechaInicio;
      if (fechaFin) body.fin = fechaFin;

      const response = await axios.post(
        `${API_URL}/public/v2/Audit/ListBranches`,
        body,
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
          },
        }
      );

      if (response.data.msg && Array.isArray(response.data.msg)) {
        const sucursal = response.data.msg.find(branch => branch.sucursal.id === idBranch);
        setNombreSucursal(sucursal ? sucursal.sucursal.name : 'Sucursal desconocida');
        setCajas(response.data.msg || []);
        setTotalPages(response.data.total || 1); // Aseguramos que total tenga un valor por defecto
      } else {
        setError('No se encontraron datos.');
      }
    } catch (err) {
      console.error('Error de la API:', err.response?.data || err.message);
      setError('Cajas no disponibles.');
    } finally {
      setIsFetching(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idBranch) {
      fetchCajas(); 
    }
  }, [idBranch]);

  return { cajas, nombreSucursal, loading, isFetching, error, fetchCajas, totalPages };
};

export default useFetchCajas;
