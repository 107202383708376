import React from 'react';
import { Modal, Button } from 'react-bootstrap';


function ModalDetalleTransferencia({ show, handleClose, transferencia }) {

  const displayValue = (value) => value || '-'; 

  // Función para reorganizar la fecha a dd-mm-aa hh:mm:ss
  const formatDate = (timestamp) => {
    if (!timestamp) return '-';

    // Reorganiza la fecha al formato dd-mm-aa hh:mm:ss sin hacer conversiones
    const formattedDate = `${timestamp.substring(8, 10)}-${timestamp.substring(5, 7)}-${timestamp.substring(0, 4)} ${timestamp.substring(11, 19)}`;
    return formattedDate;
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#fd6b0c' }}>Detalles de la Transferencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {transferencia ? (
          <>
            <p><strong>Fecha:</strong> {displayValue(formatDate(transferencia.timestamp))}</p>
            <p><strong>Tipo:</strong> {displayValue(transferencia.movimiento.types)}</p>
            <p><strong>Observaciones:</strong> {displayValue(transferencia.movimiento.observations)}</p>
            <p><strong>Balance:</strong> {displayValue(transferencia.movimiento.balance)}</p>

            <hr />

            <h5 style={{ color: '#118a80' }}>Detalles del Destinatario</h5>
            <p><strong>CVU Destinatario:</strong> {displayValue(transferencia.movimiento.cvuDest)}</p>
            <p><strong>CUIT Destinatario:</strong> {displayValue(transferencia.movimiento.cuitDest)}</p>
            <p><strong>Nombre Destinatario:</strong> {displayValue(transferencia.movimiento.nameDest)} {displayValue(transferencia.movimiento.lastNameDest)}</p>

            <hr />

            <h5 style={{ color: '#118a80' }}>Detalles del Origen</h5>
            <p><strong>CVU Origen:</strong> {displayValue(transferencia.movimiento.cvuOrig)}</p>
            <p><strong>CUIT Origen:</strong> {displayValue(transferencia.movimiento.cuitOrig)}</p>
            <p><strong>Nombre Origen:</strong> {displayValue(transferencia.movimiento.nameOrig)} {displayValue(transferencia.movimiento.lastNameOrig)}</p>
          </>
        ) : (
          <p>No se encontraron detalles.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDetalleTransferencia;
