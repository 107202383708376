import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Alert, Spinner, Card, Table, Form, Button } from 'react-bootstrap';
import { FaPlusCircle, FaSync } from 'react-icons/fa'; // Importa el ícono de recarga
import ModalAgregarCuenta from './ModalAgregarCuenta';
import './ListaDeCuentas.css';

function ListaCuentas() {
  const [cuentas, setCuentas] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false); // Estado para mostrar/ocultar el modal

  const API_URL = process.env.REACT_APP_URL; // URL de la API
  const SECRET = process.env.REACT_APP_SECRET; // Secret desde el archivo .env

  useEffect(() => {
    fetchCuentas();
  }, [API_URL]);

  const fetchCuentas = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${API_URL}/public/ListAccounts`, {
        headers: {
          'SERVICIOS-URBANOS-TOKEN': token,
        },
      });

      if (response.data.ok) {
        const cuentasData = Array.isArray(response.data.msg) ? response.data.msg : [];
        setCuentas(cuentasData);
      } else {
        setError(response.data.msg || 'Error desconocido.');
      }
    } catch (err) {
      const apiErrorMessage = err.response?.data?.msg || err.response?.data?.message || 'Error desconocido.';
      setError(apiErrorMessage);
      console.error('Error al obtener cuentas:', err.response || err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCuentaAdded = () => {
    fetchCuentas(); // Refrescar la lista de cuentas
    setShowModal(false); // Cerrar el modal después de agregar una cuenta
  };

  const filteredCuentas = cuentas?.length > 0 ? cuentas.filter((cuenta) =>
  Array.isArray(cuenta.accounts) && cuenta.accounts.some((account) =>
    typeof account === 'string'
      ? account.includes(searchTerm)
      : account.cvu.includes(searchTerm) || account.label.toLowerCase().includes(searchTerm.toLowerCase()) // Filtrar por alias (label)
  )
) : [];

  return (
    <Container className='mt-5'>
      {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
      {loading && <Spinner animation="border" />}
      
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title>Cuentas bancarias</Card.Title>
            <div className="d-flex align-items-center">
              <Button
                variant="link"
                className="ms-2"
                onClick={fetchCuentas} // Función para recargar la lista
                title="Actualizar lista"
                style={{ color: '#118a80' }}
              >
                <FaSync />
              </Button>
              <Button 
                className="agregar-button ms-2" 
                onClick={() => setShowModal(true)}
              >
                <FaPlusCircle className="me-2" /> 
                Agregar Cuenta
              </Button>
            </div>
          </div>

          <Form.Group controlId="search" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Buscar CVU o alias"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Form.Group>

          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>CVU</th>
                <th>Alias</th>
                <th>Banco</th>
              </tr>
            </thead>
            <tbody>
              {filteredCuentas.length > 0 ? (
                filteredCuentas.flatMap((cuenta, index) =>
                  cuenta.accounts.map((account, subIndex) => (
                    typeof account === 'string' ? (
                      <tr key={`${cuenta._id}-${subIndex}`}>
                        <td colSpan="3">{account}</td>
                      </tr>
                    ) : (
                      <tr key={`${cuenta._id}-${subIndex}`}>
                        <td>{account.cvu}</td>
                        <td>{account.label}</td>
                        <td>{account.name}</td>
                      </tr>
                    )
                  ))
                )
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">No se encontraron cuentas</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Modal para agregar cuenta */}
      {showModal && (
        <ModalAgregarCuenta
          show={showModal}
          handleClose={() => setShowModal(false)}
          onCuentaAdded={handleCuentaAdded} // Pasar la función para refrescar la lista
          API_URL={API_URL}
          SECRET={SECRET}
        />
      )}
    </Container>
  );
}

export default ListaCuentas;
