import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ConfirmationModal.css';


const ConfirmationModal = ({ show, handleClose, handleConfirm, cuitPasajero, nombrePasajero, monto,  isConfirming}) => {
  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="confirmation-modal">
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Recarga</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-text">¿Está seguro de que desea recargar el saldo?</p>
        <p className="modal-info"><strong>Pasajero:</strong> {nombrePasajero}</p>
        <p className="modal-info modal-cuit"><strong>CUIT:</strong> {cuitPasajero}</p>
        <p className="modal-info modal-monto"><strong>Monto:</strong> ${monto}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="modal-button-cancel">
          Cancelar
        </Button>
        <Button 
          variant="primary" 
          onClick={handleConfirm} 
          className="modal-button"
          disabled={isConfirming} 
        >
          {isConfirming ? 'Confirmando...' : 'Confirmar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
