import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchHistoricoCaja = (idBox) => {
  const [historico, setHistorico] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const API_URL = process.env.REACT_APP_URL;

    const fetchHistorico = async () => {
      setLoading(true); 
      setError(null); 

      try {
        const response = await axios.post(
          `${API_URL}/public/v2/Audit/ListBoxHistory`,
          { idBox },
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            },
          }
        );

        
        if (!response.data.ok) {
          throw new Error(response.data.msg);
        }

        setHistorico(response.data.msg || []);
      } catch (err) {
        
        setError(err.response ? err.response.data.msg : 'Error al obtener el historial de la caja.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (idBox) {
      fetchHistorico();
    }
  }, [idBox]);

  return { historico, loading, error };
};

export default useFetchHistoricoCaja;
