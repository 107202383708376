import React, { useState } from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { FaUser, FaStore, FaMapMarkerAlt, FaAddressCard, FaBalanceScale, FaCreditCard, FaMoneyBillWave, FaCity } from 'react-icons/fa';
import './Inicio.css';
import ComercioImage from '../../../assets/logo-susa-corto.png';

function Inicio() {
  const [showMore, setShowMore] = useState(false);
  const username = localStorage.getItem('username');
  const nameShop = localStorage.getItem('nameShop');


  // Datos adicionales
  const province = localStorage.getItem('province');
  const city = localStorage.getItem('city');
  const postalCode = localStorage.getItem('postalCode');
  const address = localStorage.getItem('address');
  const riskProfile = localStorage.getItem('riskProfile');
  const cvu = localStorage.getItem('cvu');
  const currency = localStorage.getItem('currency');
  const currentBalance = localStorage.getItem('currentBalance');

  const handleToggleMore = () => {
    setShowMore(!showMore); // Cambia el estado de mostrar más o menos
  };

  return (
    <Container className="inicio-container">
      <Card className="inicio-card shadow-lg mb-5 rounded">
        <div className="circle-orange circle-orange-1"></div>
        <div className="circle-orange circle-orange-2"></div>

        {showMore ? (
          // Carta de Detalles del Comercio
          <Row>
            <Col>
              <h2 className="inicio-header">Detalles del Comercio</h2>
              <Row>
                <Col md={6} className="detalle-column">
                  <p className="detalle-text">
                    <FaMapMarkerAlt className="detalle-icon info-icon" /> <strong>Provincia:</strong> {province}
                  </p>
                  <p className="detalle-text">
                    <FaCity className="detalle-icon info-icon" /> <strong>Ciudad:</strong> {city}
                  </p>
                  <p className="detalle-text">
                    <FaAddressCard className="detalle-icon info-icon" /> <strong>Código Postal:</strong> {postalCode}
                  </p>
                  <p className="detalle-text">
                    <FaAddressCard className="detalle-icon info-icon" /> <strong>Dirección:</strong> {address}
                  </p>
                </Col>
                <Col md={6} className="detalle-column">
                  <p className="detalle-text">
                    <FaBalanceScale className="detalle-icon info-icon" /> <strong>Perfil de Riesgo:</strong> {riskProfile}
                  </p>
                  <p className="detalle-text">
                    <FaCreditCard className="detalle-icon info-icon" /> <strong>CVU:</strong> {cvu}
                  </p>
                  <p className="detalle-text">
                    <FaMoneyBillWave className="detalle-icon info-icon" /> <strong>Moneda:</strong> {currency}
                  </p>
                  <p className="detalle-text">
                    <FaMoneyBillWave className="detalle-icon info-icon" /> <strong>Saldo Actual:</strong> {currentBalance}
                  </p>
                </Col>
              </Row>
              <Button variant="primary" onClick={handleToggleMore} className="caja-button mt-3">
                Ver menos
              </Button>
            </Col>
          </Row>
        ) : (
          // Carta de Inicio
          <Row className="align-items-center">
            <Col md={6} className="image-column">
              <div className="image-container">
                <img 
                  src={ComercioImage} 
                  alt="Comercio" 
                  className="img-fluid inicio-img"
                />
              </div>
            </Col>
            <Col md={6} className="text-column">
              <div className="text-container">
                <h2 className="inicio-header">Bienvenido al POS Servicios Urbanos</h2>
                <p className="info-text">
                  <FaUser className="info-icon" /> <strong>Usuario:</strong> <span className="info-data">{username}</span>
                </p>
                <p className="info-text">
                  <FaStore className="info-icon" /> <strong>Comercio:</strong> <span className="info-data">{nameShop}</span>
                </p>
                <Button variant="primary" onClick={handleToggleMore} className="caja-button mt-3">
                  Ver más
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </Container>
  );
}

export default Inicio;
