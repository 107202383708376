import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import './Saldo.css';

function Saldo() {
  const [saldo, setSaldo] = useState({
    balanceCaja: null,
  });
  const [loading, setLoading] = useState(true);
  const cuit = localStorage.getItem('cuit');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchSaldo = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/public/v2/Balance`,
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
              'page': 1
            }
          }
        );

        if (response.data) {
          setSaldo({
            balanceCaja: response.data.msg !== null ? response.data.msg : 0, 
          });
        }
        setLoading(false);
      } catch (error) {
     //   console.error('Error fetching saldo:', error);
        setLoading(false);
      }
    };

    fetchSaldo();
  }, [cuit, token]);

  return (
    <Container fluid className="saldo-container">
      <Row className="justify-content-center align-items-center" style={{ width: '100%' }}>
        <Col className="text-center">
          <Card className="saldo-card">
            <Card.Body>
              {loading ? (
                <p className="text-center loading-text">Cargando...</p>
              ) : (
                <Table bordered className="saldo-table">
                  <thead>
                    <tr>
                      <th>Saldo Efectivo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ fontSize: '2rem', }}>
                        ${saldo.balanceCaja}
                      </td> 
                    </tr>
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Saldo;
