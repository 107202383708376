import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchBranches = () => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBranches = async () => {
    const token = localStorage.getItem('token');

 
    setError(null); 
    setLoading(true);

    if (!token) {
      setError('No se encontró el token.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/public/v2/List/Branches`, {
        headers: {
          'SERVICIOS-URBANOS-TOKEN': token, 
        },
      });

      setBranches(response.data.msg || []);  
    } catch (err) {
      setError('Error al obtener las sucursales.'); 
      //console.error(err);
    } finally {
      setLoading(false); 
    }
  };

 
  useEffect(() => {
    fetchBranches();
  }, []);

  return { branches, loading, error, refetch: fetchBranches };
};

export default useFetchBranches; 
