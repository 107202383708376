import React, { useState } from 'react';
import { Table, Card, Button, Form, InputGroup } from 'react-bootstrap';
import useFetchCajas from '../Hooks/Gerente/useFetchTodasLasCajas';
import CajaDetailsModal from './DetalleCajaModal';
import { FaSearch } from 'react-icons/fa';
import './TodasLasCajas.css';

function TodasLasCajas() {
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedCaja, setSelectedCaja] = useState(null);
  const [fetchParams, setFetchParams] = useState({ startDate: '', endDate: '', name: '', lastName: '' });

  const { data, loading, error } = useFetchCajas(currentPage, fetchParams.startDate, fetchParams.endDate, fetchParams.name, fetchParams.lastName);

  const handleShowModal = (caja) => {
    setSelectedCaja(caja);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNextPage = () => {
    if (currentPage < (data?.total || 1)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = () => {
    if (!startDate || !endDate) {
      alert('Por favor, ingresa ambas fechas para realizar la búsqueda.');
      return;
    }

    setFetchParams({
      startDate,
      endDate,
      name,
      lastName,
    });
    setCurrentPage(1);
  };

  return (
    <div className="todas-cajas-container">
      <Card className="todas-cajas-card mb-4">
        <Card.Body>
          <h2 className="todas-cajas-header">Lista de Cajas</h2>

          <div className="d-flex align-items-center mb-3">
            <InputGroup className="me-2" style={{ minWidth: '200px' }}>
              <Form.Control
                type="text"
                placeholder="Buscar por nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputGroup>

            <InputGroup className="me-2" style={{ minWidth: '200px' }}>
              <Form.Control
                type="text"
                placeholder="Buscar por apellido"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </InputGroup>

            <InputGroup className="me-2" style={{ width: '300px' }}>
              <InputGroup.Text style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>Inicio:</InputGroup.Text>
              <Form.Control 
                type="date" 
                value={startDate} 
                onChange={(e) => setStartDate(e.target.value)} 
                style={{ height: '38px' }}
              />
            </InputGroup>

            <InputGroup className="me-2" style={{ width: '300px' }}>
              <InputGroup.Text style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>Fin:</InputGroup.Text>
              <Form.Control 
                type="date" 
                value={endDate} 
                onChange={(e) => setEndDate(e.target.value)} 
                style={{ height: '38px' }}
              />
            </InputGroup>

            <Button 
              variant="primary" 
              onClick={handleSearch} 
              disabled={loading} 
              style={{ width: '10%', height: '38px' }} 
              className='btn btn-primary custom-button'
            >
              <FaSearch className="me-1" />
              {loading ? 'Buscando...' : 'Buscar'}
            </Button>
          </div>

          {loading && <p>Cargando...</p>}
          {error && <p>Error al cargar las cajas: {error}</p>}

          {!loading && !error && (
            <>
              <Table striped bordered hover className="todas-cajas-table">
  <thead>
    <tr>
      <th>Id Caja</th>
      <th>Balance</th>
      <th>Apertura</th>
      <th>Empleado</th>
      <th>Comercio</th>
      <th>Sucursal</th>
      <th>Acciones</th>
    </tr>
  </thead>
  <tbody>
    {Array.isArray(data) && data.length > 0 ? (
      data.map((caja) => (
        <tr key={caja._id}>
          <td>{caja.idBox}</td>
          <td>{caja.balance}</td>
          <td>{caja.dateOpen ? caja.dateOpen.replace('T', ' ').replace('Z', '') : 'Fecha no disponible'}</td>
          <td>
            {caja.empleado 
              ? `${caja.empleado.name || 'Nombre no disponible'} ${caja.empleado.lastName || ''}` 
              : 'Empleado no disponible'}
          </td>
          <td>{caja.comercio?.nameShop || 'Comercio no disponible'}</td>
          <td>{caja.comercio?.branches?.name || 'Sucursal no disponible'}</td>
          <td>
            <a href="#" className="link-ver-mas" onClick={() => handleShowModal(caja)}>
              Ver Detalle
            </a>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="7" className="text-center">Ingresa filtros de búsqueda</td>
      </tr>
    )}
  </tbody>
</Table>



              <div className="lista-saldos-buttons">
                <Button
                  variant="secondary"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Anterior
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleNextPage}
                  disabled={currentPage === (data?.total || 1)}
                >
                  Siguiente
                </Button>
              </div>
            </>
          )}

          <CajaDetailsModal 
            show={showModal} 
            handleClose={handleCloseModal} 
            caja={selectedCaja} 
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default TodasLasCajas;
