import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Spinner, Button } from 'react-bootstrap';
import TablaEmpleados from './TablaEmpleados';
import TablaCajas from './TablaCajas';
import DetalleCaja from './DetalleCaja';
import TablaHistorial from './TablaHistorial';
import { Card } from 'react-bootstrap';

function ListaDeEmpleados() {
  const [empleados, setEmpleados] = useState([]);
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(null);
  const [cajas, setCajas] = useState([]);
  const [detalleCaja, setDetalleCaja] = useState(null);
  const [historial, setHistorial] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState('empleados');
  const [selectedIdBox, setSelectedIdBox] = useState('');

  const API_URL = process.env.REACT_APP_URL;

  useEffect(() => {
    let isMounted = true;

    const obtenerEmpleados = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');

      try {
        const response = await axios.post(
          `${API_URL}/public/v2/List/Employees`,
          {},
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            },
          }
        );
        if (isMounted) {
          //console.log('Respuesta de la API:', response.data);

      response.data.msg.forEach((empleado, index) => {
        //console.log(`Empleado ${index + 1}:`, empleado);
      });
          // Asegurar que cada empleado tenga el atributo isActive y que sea booleano
          const empleadosConEstado = response.data.msg.map((empleado) => ({
            ...empleado,
            isActive: empleado.state!== undefined ? empleado.state : false, // Si isActive no está definido, se asigna false
          }));

          setEmpleados(empleadosConEstado);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.response?.data?.msg || 'Error desconocido.');
          console.error('Error al obtener empleados:', err);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    obtenerEmpleados();

    return () => {
      isMounted = false;
    };
  }, [API_URL]);

  const handleVerCajas = async (empleado) => {
    let isMounted = true;
    setEmpleadoSeleccionado(empleado);
    setCajas([]);
    setDetalleCaja(null);
    setHistorial([]);
    setError(''); // Limpiar el mensaje de error
    setLoading(true);
    setCurrentView('cajas');
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/List/Employees/One`,
        { idEmployees: empleado._id },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (isMounted) {
        if (response.data.ok) {
          setCajas(response.data.msg);
        } else {
          setError(response.data.msg);
        }
      }
    } catch (err) {
      if (isMounted) {
        setError(err.response?.data?.msg || 'Error desconocido.');
        console.error('Error al obtener las cajas del empleado:', err);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  
  const handleVerDetalleCaja = async (idBox, idBranches) => {
    setSelectedIdBox(idBox); 
    setDetalleCaja(null); 
    let isMounted = true;
    setLoading(true);
    setError('');
    setCurrentView('detalleCaja');
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/List/Employees/One/Box`,
        { idBox, idBranches },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (isMounted) {
        if (response.data.ok) {
          setDetalleCaja(response.data.msg[0]);
        } else {
          setError(response.data.msg);
        }
      }
    } catch (err) {
      if (isMounted) {
        setError(err.response?.data?.msg || 'Error desconocido.');
        console.error('Error al obtener el detalle de la caja:', err);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  const handleVerHistorico = async (idBox) => {
    let isMounted = true;
    setLoading(true);
    setHistorial([]);
    setError('');
    setCurrentView('historial'); 
  
    
    const token = localStorage.getItem('token');
  
    if (!token) {
      setError('Token de autenticación no encontrado.');
      setLoading(false);
      return;
    }
  
 
    //console.log('Solicitando historial para idBox:', idBox);
  
    if (!idBox) {
      setError('El idBox es inválido o no está definido.');
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.post(
        `${API_URL}/public/v2/List/History`,
        { idBox },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token, 
          },
        }
      );
  
      //console.log('Respuesta de la API para el historial:', response.data);
  
      if (isMounted) {
        if (response.data.ok) {
          //console.log('Historial encontrado:', response.data.msg);
          setHistorial(response.data.msg); 
        } else {
          setError('No se encontraron registros para el historial.');
        }
      }
    } catch (err) {
      if (isMounted) {
        setError(err.response?.data?.msg || 'Error desconocido.');
        console.error('Error al obtener el historial de la caja:', err);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  
    return () => {
      isMounted = false;
    };
  };
  
  
  

  const handleBack = () => {
    setError(''); // Limpiar el mensaje de error al volver atrás
    if (currentView === 'historial') {
      setCurrentView('detalleCaja');
    } else if (currentView === 'detalleCaja') {
      setCurrentView('cajas');
    } else if (currentView === 'cajas') {
      setCurrentView('empleados');
    }
  };

  return (
    <Container className='mt-5'>
      
      {loading && <Spinner animation="border" />}

      {currentView !== 'empleados' && (
        <Button variant="secondary" onClick={handleBack} className="mb-3">
          ← Volver
        </Button>
      )}

      {currentView === 'empleados' && (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Lista de Empleados</Card.Title>
            <TablaEmpleados empleados={empleados} onVerCajas={handleVerCajas} />
          </Card.Body>
        </Card>
      )}

      {currentView === 'cajas' && (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Lista de Cajas</Card.Title>
            <TablaCajas cajas={cajas} onVerDetalleCaja={handleVerDetalleCaja} />
          </Card.Body>
        </Card>
      )}

{currentView === 'detalleCaja' && (
  <Card className="mb-4">
    <Card.Body>
      <Card.Title>Detalle de la Caja</Card.Title>
      <DetalleCaja detalle={detalleCaja} idBox={selectedIdBox} onVerHistorico={handleVerHistorico} />
    </Card.Body>
  </Card>
)}


      {currentView === 'historial' && historial.length > 0 && (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Historial de Movimientos</Card.Title>
            <TablaHistorial movimientos={historial} />
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}

export default ListaDeEmpleados;
