import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Card } from 'react-bootstrap';
import logoutImage from '../../assets/logoutimage.png';
import './Logout.css'; 

function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('cuit');
    localStorage.removeItem('correo');
    localStorage.removeItem('nombre');
    navigate('/login');
  };

  return (
    <Container className="logout-container d-flex justify-content-center align-items-center">
      <Card className="logout-card">
        <Card.Body className="text-center">
          <img src={logoutImage} alt="Cerrar Sesión" className="logout-image mb-4" />
          <h2 className="logout-header mb-4">¿Estás seguro de que deseas cerrar sesión?</h2>
          <Button variant="danger" className="logout-button" onClick={handleLogout}>
            Cerrar Sesión
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Logout;
