import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

function BuscarTransferenciasPorFecha({ transferencias, onTransferenciasFiltradas }) {
  const [fechaInicio, setFechaInicio] = useState(''); 
  const [fechaFin, setFechaFin] = useState(''); 
  const [cargando, setCargando] = useState(false); 

  const handleBuscar = async (e) => {
    e.preventDefault();

    if (!fechaInicio || !fechaFin) return; 

    setCargando(true); 

    // Filtrar transferencias por fecha
    const transferenciasFiltradas = transferencias.filter((transferencia) => {
      const transferenciaFecha = transferencia.timestamp.substring(0, 10); 
      return transferenciaFecha >= fechaInicio && transferenciaFecha <= fechaFin; 
    });

   
    await new Promise((resolve) => setTimeout(resolve, 1000));

    onTransferenciasFiltradas(transferenciasFiltradas);
    setCargando(false); // Restablece el estado a no cargando
  };

  return (
    <Form onSubmit={handleBuscar} className="d-flex align-items-center">
      <InputGroup className="input-group-sm me-2">
        <span className="input-group-text">Inicio:</span>
        <Form.Control
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          required
          className="form-control"
          style={{ minWidth: '150px' }}
        />
        <span className="input-group-text">Fin:</span>
        <Form.Control
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          required
          className="form-control"
          style={{ minWidth: '150px' }}
        />
        <Button type="submit" className="btn btn-primary custom-button d-flex align-items-center">
          {cargando ? 'Buscando...' : <><FaSearch className="me-1" /> Buscar</>}
        </Button>
      </InputGroup>
    </Form>
  );
}

export default BuscarTransferenciasPorFecha;
