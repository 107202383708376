import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';
import './Empleados.css';

function TablaEmpleados({ empleados, onVerCajas }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingState, setLoadingState] = useState(false); 
  const [error, setError] = useState(''); 
  const [empleadosState, setEmpleadosState] = useState(empleados); 
  const empleadosPerPage = 9;
  const API_URL = process.env.REACT_APP_URL;

  useEffect(() => {
    setEmpleadosState(empleados);
  }, [empleados]);

  const filteredEmpleados = empleadosState.filter((empleado) =>
    `${empleado.name} ${empleado.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastEmpleado = currentPage * empleadosPerPage;
  const indexOfFirstEmpleado = indexOfLastEmpleado - empleadosPerPage;
  const currentEmpleados = filteredEmpleados.slice(indexOfFirstEmpleado, indexOfLastEmpleado);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleChangeState = async (empleadoId, currentState) => {
    setLoadingState(true); 
    setError(''); 
    const token = localStorage.getItem('token');

   // console.log(`ID del empleado enviado: ${empleadoId}`);

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/ChangeState/Employees`,
        { id: empleadoId, state: !currentState },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (response.data.ok) {
        setEmpleadosState((prevEmpleados) =>
          prevEmpleados.map((empleado) =>
            empleado._id === empleadoId ? { ...empleado, isActive: !currentState } : empleado
          )
        );
      } else {
        setError(`Error: ${response.data.msg}`); 
      }
    } catch (err) {
      console.error('Error al cambiar el estado del empleado:', err);
      setError('Error al cambiar el estado del empleado.'); 
    } finally {
      setLoadingState(false); 
    }
  };

  return (
    <div>
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}

      <Form.Group controlId="search" className="mb-3">
        <Form.Control
          type="text"
          placeholder="Buscar empleado por nombre o apellido"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Form.Group>

      {/* Mostrar el spinner mientras se cargan los empleados */}
      {loadingState ? (
        <div className="d-flex justify-content-center mt-4">
          <Spinner animation="border" role="status">
            <span className="sr-only">Cargando...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Email</th>
                <th>CUIT</th>
                <th>Cajas</th>
                <th>Acciones</th> 
              </tr>
            </thead>
            <tbody>
              {currentEmpleados.length > 0 ? (
                currentEmpleados.map((empleado) => (
                  <tr key={empleado._id}>
                    <td>{empleado.name}</td>
                    <td>{empleado.lastName}</td>
                    <td>{empleado.email}</td>
                    <td>{empleado.cuit}</td>
                    <td>
                      <Button variant="link" onClick={() => onVerCajas(empleado)}>
                        Ver Cajas
                      </Button>
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`switch-${empleado._id}`}
                        label={empleado.isActive ? 'Activo' : 'Inactivo'}
                        checked={empleado.isActive}
                        onChange={() => handleChangeState(empleado._id, empleado.isActive)}
                        disabled={loadingState}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">No hay empleados para mostrar</td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between">
            <Button
              className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              className={`pagination-button ${indexOfLastEmpleado >= filteredEmpleados.length ? 'disabled' : ''}`}
              onClick={handleNextPage}
              disabled={indexOfLastEmpleado >= filteredEmpleados.length}
            >
              Siguiente
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default TablaEmpleados;
