import React from 'react';
import { Table, Card } from 'react-bootstrap'; 
import { useParams } from 'react-router-dom';
import useFetchHistoricoCaja from '../Hooks/Auditor/useFetchHistoricoCaja';


const HistoricoCaja = () => {
  const { idBox } = useParams();  // Obtener el id de la caja de la URL
  const { historico, loading, error } = useFetchHistoricoCaja(idBox);

  if (loading) return <div>Cargando historial...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="historico-caja-container">
      <Card className="historico-caja-card">
        <Card.Body>
          <h3 className="historico-caja-header">Historial de la Caja {idBox}</h3>
          <Table striped bordered hover className="historico-caja-table">
            <thead>
              <tr>
                <th>CVU</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>CUIT</th>
                <th>Balance</th>
                <th>Saldo en Caja</th>
                <th>Tipo</th>
              </tr>
            </thead>
            <tbody>
              {historico.map((movimiento) => (
                <tr key={movimiento._id}>
                  <td>{movimiento.movimiento.cvu}</td>
                  <td>{movimiento.movimiento.nameOrig}</td>
                  <td>{movimiento.movimiento.lastNameOrig}</td>
                  <td>{movimiento.movimiento.cuit}</td>
                  <td>{movimiento.movimiento.balance}</td>
                  <td>{movimiento.movimiento.boxCash}</td>
                  <td>{movimiento.movimiento.types}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default HistoricoCaja;
