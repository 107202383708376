import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

function BuscarTransferenciasPorFecha({ transferencias, onTransferenciasFiltradas }) {
  const [fecha, setFecha] = useState('');  // Campo de búsqueda por fecha

  const handleBuscar = (e) => {
    e.preventDefault();

    if (!fecha) return;

    // Filtrar transferencias por fecha
    const transferenciasFiltradas = transferencias.filter((transferencia) => {
      const transferenciaFecha = transferencia.timestamp.substring(0, 10); // Obtener solo la parte de la fecha
      return transferenciaFecha === fecha; // Comparar con la fecha seleccionada
    });

    onTransferenciasFiltradas(transferenciasFiltradas);
  };

  return (
    <Form onSubmit={handleBuscar} className="d-flex align-items-center">
      <InputGroup className="input-group-sm me-2"> {/* Ajusta el tamaño del InputGroup */}
        <Form.Control
          type="date"
          value={fecha}
          onChange={(e) => setFecha(e.target.value)}
          required
          className="form-control-sm"
        />
        <Button type="submit" className="btn btn-primary custom-button-lupa d-flex align-items-center">
          <FaSearch className="me-1" />
        </Button>
      </InputGroup>
    </Form>
  );
}

export default BuscarTransferenciasPorFecha;
