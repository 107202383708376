import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/logo-susa-corto.png';
import './Header.css';

function Header() {
  const nombre = localStorage.getItem('nombre'); // Nombre del comercio
  const username = localStorage.getItem('username'); // Nombre de usuario
  const rol = localStorage.getItem('role'); // Obtener el rol del usuario

  //console.log('nombre:', nombre);
 // console.log('username:', username);
  //console.log('rol:', rol);

  // Determina qué nombre mostrar basado en el rol
  const displayName = rol === 'comercio' ? username : nombre;

  return (
    <Navbar className="header-navbar">
      <Container fluid className="d-flex justify-content-between align-items-center">
        <Row className="align-items-center">
          <Col className="logo-col">
            <img
              src={logo}
              height="40"
              className="d-inline-block align-top"
              alt="Logo" 
            />
          </Col>
          <Col className="username-col">
            {displayName && displayName.trim() && (  
              <Navbar.Text className="username-text">
                Bienvenido, {displayName}
              </Navbar.Text>
            )}
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default Header;
