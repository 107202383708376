import React, { useState } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';

function SearchResults({ searchResults }) {
  const [selectedComprobante, setSelectedComprobante] = useState(null);
  const [fileUrl, setFileUrl] = useState('');

  const handleShowModal = (comprobante) => {
    const file = searchResults.urls.find(urlObj => urlObj.data.nameDoc === comprobante.nameDoc);

    if (file) {
      setFileUrl(file.url);
      setSelectedComprobante(comprobante);
    } else {
      // console.error('File not found for comprobante:', comprobante);
    }
  };

  const handleCloseModal = () => {
    setSelectedComprobante(null);
    setFileUrl('');
  };

  const formatObservation = (observation) => {
    return observation ? observation : '-';
  };

  const formatNameDoc = (nameDoc) => {
    const dateTime = nameDoc.split('.')[0]; // Eliminar la extensión del archivo
    const [date, time] = dateTime.split(' '); // Separar la fecha y la hora
    const [year, month, day] = date.split('-'); // Separar año, mes y día
    return `${day}-${month}-${year.slice(2)} ${time}`;
  };

  return (
    <>
      {searchResults.data.length > 0 && (
        <div className="mt-3">
          <h3>Resultados:</h3>
          <Table striped bordered hover className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Estado</th>
                <th>Observación</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.data.map((result, index) => {
                return (
                  <tr key={result._id}>
                    <td>{index + 1}</td>
                    <td>{formatNameDoc(result.nameDoc)}</td>
                    <td>{result.amount}</td>
                    <td>{result.state}</td>
                    <td>{formatObservation(result.observation)}</td>
                    <td>
                      <Button variant="link" onClick={() => handleShowModal(result)}>
                        Ver
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <Modal show={!!selectedComprobante} onHide={handleCloseModal} centered dialogClassName="modal-custom">
        <Modal.Header closeButton>
          <Modal.Title>Comprobante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fileUrl && (
            <iframe
              src={fileUrl}
              title="Comprobante"
              style={{ width: '100%', height: '500px' }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SearchResults;
