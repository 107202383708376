import React, { useState } from 'react';
import axios from 'axios';
import { Button, Alert, Card } from 'react-bootstrap';
import CajaImage from '../../../assets/abrirCaja.png';
import FallbackImage from '../../../assets/caja.png'; 
import './Caja.css';
import ListaDeCajas from './ListaDeCajas';
import ConfirmModal from './ConfirmModal'; 

const CerrarCaja = () => {
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');
  const [imageSrc, setImageSrc] = useState(CajaImage); 
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleImageError = () => {
    setImageSrc(FallbackImage);
  };

  const handleCerrarCaja = async () => {
    setIsLoading(true);  // Inicia el estado de carga
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/public/v2/Closed`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token')
        }
      });
  
      if (response.status === 200 && response.data.ok) {
        setMensaje(response.data.msg);
        setError('');
  
        try {
          const commissionsResponse = await axios.post(`${process.env.REACT_APP_URL}/public/v1/Shops/List/Commission`, {}, {
            headers: {
              'Content-Type': 'application/json',
              'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token')
            }
          });
  
          if (commissionsResponse.status === 200 && commissionsResponse.data.ok) {
            //console.log('Commissions:', commissionsResponse.data.lista);
          } else {
            //console.error('Error fetching commissions:', commissionsResponse.data.msg);
          }
        } catch (error) {
          //console.error('Error fetching commissions:', error);
        }
  
      } else {
        setError(response.data.msg);
        setMensaje('');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg);
        setMensaje('');
      } else {
        setError('Error al cerrar la caja');
        setMensaje('');
      }
    } finally {
      setIsLoading(false);  // Finaliza el estado de carga
    }
  };
  

  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmCerrarCaja = () => {
    handleCloseConfirmModal();
    handleCerrarCaja();
  };

  return (
    <div className="caja-container">
      <Card className="caja-card">
        <div className="text-center">
          <h1 className="caja-header">Cerrar Caja</h1>
          {mensaje && <Alert variant="success">{mensaje}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <img
            src={imageSrc}
            alt="Cerrar Caja"
            style={{ width: '400px', height: 'auto' }}
            className="img-fluid mb-4 center-image caja-image"
            onError={handleImageError}
          />
          <Button
          variant="primary"
          onClick={handleShowConfirmModal}
          className="caja-button"
          disabled={isLoading}  // Deshabilitar el botón mientras está cargando
          >
  {isLoading ? 'Cerrando caja...' : 'Cerrar Caja'}
</Button>

        </div>
      </Card>
      <ListaDeCajas />

      <ConfirmModal 
        show={showConfirmModal} 
        handleClose={handleCloseConfirmModal} 
        handleConfirm={handleConfirmCerrarCaja}
      />
    </div>
  );
};

export default CerrarCaja;
