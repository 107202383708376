import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import './ModalNewPassword.css';

function ModalNewPassword({ show, handleClose, user }) {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleCodeChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Mover el foco al siguiente input si el valor actual no está vacío
      if (value && index < 5) {
        document.getElementById(`codeInput${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('Text');
    if (/^\d{6}$/.test(pasteData)) {
      const pastedCode = pasteData.split('');
      setCode(pastedCode);
      document.getElementById(`codeInput5`).focus();
      e.preventDefault();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && code[index] === '' && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = '';
      setCode(newCode);
      document.getElementById(`codeInput${index - 1}`).focus();
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
  
    const codeString = code.join('');
  
    if (newPassword !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/public/v2/NewPassword`,
        {
          cod: codeString,
          newpass: newPassword,
        },
        {
          headers: { SECRET: process.env.REACT_APP_SECRET }, 
        }
      );
  
      if (response.data && response.data.ok) {
        setSuccessMessage('Contraseña creada exitosamente.');
        handleClose(true);
      } else {
        setError('Error al crear la contraseña. Intenta de nuevo.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg);
      } else {
        setError('Error al crear la contraseña. Intenta de nuevo.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crear Nueva Contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Se ha enviado un código a tu correo electrónico vínculado a tu cuenta para que puedas crear tu contraseña nueva.</p>
        {error && <Alert variant="danger">{error}</Alert>}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        <Form>
          <Form.Group controlId="formCode">
            <Form.Label>Código</Form.Label>
            <div className="code-inputs">
              {code.map((digit, index) => (
                <Form.Control
                  key={index}
                  id={`codeInput${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => handleCodeChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  maxLength="1"
                  className="text-center code-input"
                  style={{ width: '3rem', marginRight: '0.5rem' }}
                />
              ))}
            </div>
          </Form.Group>
          <Form.Group controlId="formNewPassword" className="mt-3">
            <Form.Label>Nueva Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingresa nueva contraseña"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formConfirmPassword" className="mt-3">
            <Form.Label>Confirmar Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirma tu nueva contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
    
        <Button
          variant="primary"
          style={{ backgroundColor: '#FF5100', borderColor: '#FF5100' }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#FF7043')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#FF5100')}
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? 'Guardando...' : 'Guardar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalNewPassword;
