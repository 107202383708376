import React, { useState, useEffect } from 'react';
import { Table, Card, Form, Button, InputGroup } from 'react-bootstrap'; 
import { useParams, useNavigate } from 'react-router-dom';
import useFetchCajas from '../Hooks/Auditor/useFetchListaDeCajas';
import CajaModal from './CajaModal';  
import { FaSearch } from 'react-icons/fa';

const CajasSucursal = () => {
  const { id } = useParams(); 
  const { cajas, loading, isFetching, error, fetchCajas } = useFetchCajas(id); // Pasa el id aquí
  const navigate = useNavigate();  

  const [showModal, setShowModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  
  // Estado para el filtro de búsqueda
  const [searchTerm, setSearchTerm] = useState('');
  const [fechaInicio, setFechaInicio] = useState(''); // Estado para fecha de inicio
  const [fechaFin, setFechaFin] = useState(''); // Estado para fecha de fin
  const [cargando, setCargando] = useState(false); // Estado para el cargando

  const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual
 const [totalPages, setTotalPages] = useState(1);   // Estado para el total de páginas


  const nombreSucursal = cajas.length > 0 ? cajas[0].sucursal.name : 'Nombre de sucursal no disponible';

  const formatFechaExacta = (fecha) => {
    if (!fecha) return 'Sin cerrar';
    const [date, time] = fecha.split('T'); 
    const [year, month, day] = date.split('-'); 
    return `${day}-${month}-${year.slice(2)} ${time.split('.')[0]}`;
  };

  const formatBalance = (balance) => {
    if (balance === null || balance === undefined || balance === '') return '-';
    return `$${balance}`;
  };

  const handleShowModal = (idBox) => {
    setSelectedBox(idBox); 
    setShowModal(true);   
  };

  const handleCloseModal = () => {
    setShowModal(false);  
    setSelectedBox(null);  
  };

  const handleVerHistorico = (idBox) => {
    navigate(`/cajas-sucursales/${id}/historial/${idBox}`);
  };

  // Filtrar las cajas por el nombre o apellido del empleado
  const filteredCajas = cajas.filter((caja) => {
    const nombreCompleto = `${caja.empleado.name} ${caja.empleado.lastName}`.toLowerCase();
    const transferenciaFecha = caja.dateOpen.substring(0, 10); // Obtener la fecha de apertura
    const fechaInicioValida = !fechaInicio || transferenciaFecha >= fechaInicio;
    const fechaFinValida = !fechaFin || transferenciaFecha <= fechaFin;

    return (
      nombreCompleto.includes(searchTerm.toLowerCase()) &&
      fechaInicioValida &&
      fechaFinValida
    );
  });

  const handleBuscar = () => {
    // Llama a fetchCajas solo si hay fechas de inicio y fin
    if (fechaInicio && fechaFin) {
      setCargando(true); // Cambia el estado a cargando
      fetchCajas(fechaInicio, fechaFin) // Llama a la función de búsqueda
        .finally(() => setCargando(false)); // Restablece el estado a no cargando después de la búsqueda
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Efecto para cargar las cajas al montar el componente o cuando el id cambie
  useEffect(() => {
    fetchCajas(currentPage); // Realiza la consulta inicial sin fechas
  }, [id, currentPage]); // Solo se ejecuta cuando 'id' cambia

  if (loading || isFetching) return <div>Cargando cajas...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="cajas-sucursal-container">
      <Card className="cajas-sucursal-card">
        <Card.Body>
          <h3 className="cajas-sucursal-header">
            Cajas de la sucursal {nombreSucursal}
          </h3>

          {/* Inputs para la búsqueda de nombre, fecha y botón */}
          <div className="d-flex align-items-center mb-3">
            <InputGroup className="me-1" style={{ minWidth: '100px' }}>
              <Form.Control
                type="text"
                placeholder="Buscar por nombre o apellido"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>

            <InputGroup className="me-2" style={{ width: '300px' }}> 
              <InputGroup.Text style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>Inicio:</InputGroup.Text>
              <Form.Control
                type="date"
                value={fechaInicio}
                onChange={(e) => setFechaInicio(e.target.value)}
                required
              />
            </InputGroup>

            <InputGroup className="me-2" style={{ width: '300px' }}> 
              <InputGroup.Text style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>Fin:</InputGroup.Text>
              <Form.Control
                type="date"
                value={fechaFin}
                onChange={(e) => setFechaFin(e.target.value)}
                required
              />
            </InputGroup>

            <Button variant="primary" className='btn btn-primary custom-button' onClick={handleBuscar} disabled={cargando}>
              {cargando ? 'Buscando...' : <><FaSearch className="me-1" /></>}
            </Button>
          </div>

          <Table striped bordered hover className="cajas-sucursal-table">
            <thead>
              <tr>
                <th>ID Caja</th>
                <th>Nombre</th> 
                <th>Apellido</th> 
                <th>Fecha de Apertura</th>
                <th>Fecha de Cierre</th>
                <th>Balance</th>
                <th>Acciones</th> 
                <th>Ver Histórico</th> 
              </tr>
            </thead>
            <tbody>
              {filteredCajas.map((caja) => (
                <tr key={caja._id}>
                  <td>{caja._id}</td> 
                  <td>{caja.empleado.name}</td> 
                  <td>{caja.empleado.lastName}</td> 
                  <td>{formatFechaExacta(caja.dateOpen)}</td>
                  <td>{formatFechaExacta(caja.dateClose)}</td>
                  <td>{formatBalance(caja.balance)}</td>
                  <td>
                    <a className="link-ver-mas" onClick={() => handleShowModal(caja.idBox)}>
                      Ver Caja
                    </a>
                  </td>
                  <td>
                    <a className="link-ver-mas" onClick={() => handleVerHistorico(caja.idBox)}>
                      Ver Histórico
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="lista-saldos-buttons">
            <Button
              variant="secondary"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <span className="mx-2">Página {currentPage} de {totalPages}</span>
            <Button
              variant="secondary"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Siguiente
            </Button>
          </div>

        </Card.Body>
      </Card>

      {selectedBox && (
        <CajaModal 
          show={showModal} 
          onHide={handleCloseModal} 
          idBox={selectedBox} 
        />
      )}
    </div>
  );
};

export default CajasSucursal; 
