import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route, Navigate } from 'react-router-dom';
import RecargaSaldo from '../RolEmpleado/RecargaSaldo/RecargaSaldo';
import Saldo from '../Saldo/Saldo';
import Comprobantes from '../Comprobantes/Comprobantes';
import CerrarCaja from '../FuncionesAdministrativas/Caja/CerrarCaja';
import Header from '../Header/Header';
import Logout from '../FuncionesAdministrativas/Logout';
import Sidebar from '../Sidebar/Sidebar';
import MiComercio from '../MiComercio/MiComercio';
import ListaDeSaldos from '../RolEmpleado/ListaDeSaldos/ListaDeSaldos';
import Empleados from '../RolComercio/Empleados/ListaEmpleados.js/ListaEmpleados'
import ListaDeEmpleados from '../Susa/EmpleadosSusa/ListaEmpleados.js/ListaEmpleados';
import AddEmpleado from '../RolComercio/Empleados/AgregarEmpleado.js/AddEmpleado';
import AddSucursal from '../RolComercio/AddSucursal.js/AddSucursal';
import AbrirCaja from '../FuncionesAdministrativas/Caja/AbrirCaja';
import SucursalesComercio from '../RolComercio/Sucursales/SucursalesComercio';
import CajasSucursal from '../Auditor/ListaCajasSuc';
import ListaSucursales from '../RolComercio/ListaSucursales/ListaSucursales';
import HistoricoCaja from '../Auditor/HistoricoCaja';
import InicioAuditor from '../Auditor/InicioAuditor';
import ListaCuentas from '../RolComercio/CuentasBancarias/ListadeCuentas';
import Inicio from '../RolComercio/Inicio/Inicio';
import ListaTransferencias from '../RolComercio/Transferencias/ListadeTransferencias';
import HistorialCaja from '../FuncionesAdministrativas/Caja/HistorialCajaCerrada';
import ListaTransferenciasAuditor from '../Auditor/TransferenciasAuditor/ListadeTransferencias';
import TablaEmpleados from '../Susa/EmpleadosSusa/ListaEmpleados.js/TablaEmpleados';


import './Dashboard.css'; 
import TodasLasCajas from '../Gerente/TodasLasCajas';

function Dashboard() {
  return (
    <Container fluid>
      <Row>
        <Header />
      </Row>
      <Row>
        <Col xs={2}>
          <Sidebar />
        </Col>
        <Col xs={10} className="main-content" id="page-content-wrapper">
          <Routes>
            <Route path="/recarga-saldo" element={<RecargaSaldo />} />
            <Route path="/saldo" element={<Saldo />} />
            <Route path="/comprobantes" element={<Comprobantes />} />
            <Route path="/funciones-administrativas/cerrar-caja" element={<CerrarCaja />} />
            <Route path="/funciones-administrativas/abrir-caja" element={<AbrirCaja />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/funciones-administrativas/logout" element={<Logout />} />
            <Route path="/mi-comercio" element={<MiComercio />} />
            <Route path="/lista-de-saldos" element={<ListaDeSaldos />} />
            <Route path='/lista-empleados' element={<Empleados />}/>
            <Route path='/agregar-empleado' element={<AddEmpleado />}/>
            <Route path='/agregar-sucursal' element={<AddSucursal />}/>
            {/*<Route path='/lista-sucursales' element={<SucursalesEmp />}/>*/}
            <Route path='/lista-sucursales' element={<SucursalesComercio />}/>
            <Route path='/lista-de-sucursales' element={<ListaSucursales />} />
            <Route path='/lista-de-cuentas' element={<ListaCuentas />} />
            <Route path='/lista-de-transferencias' element={<ListaTransferencias />} />
            <Route path='/lista-de-transferencias-auditor' element={<ListaTransferenciasAuditor />} />
            <Route path="/cajas-sucursales/:id/historial/:idBox" element={<HistoricoCaja />} />
            <Route path='/empleados-admin' element={<ListaDeEmpleados />} />
        
            <Route path='inicio' element={<Inicio />} />
            <Route path='/inicio-auditor' element= {<InicioAuditor />} />
            <Route path='/todas-las-cajas' element ={<TodasLasCajas />} />
            <Route path="/historial/:idBox" element={<HistorialCaja />} />
            <Route path="/cajas-sucursales/:id" element={<CajasSucursal />} />

          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
