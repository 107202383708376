import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function CajaDetailsModal({ show, handleClose, caja }) {
  if (!caja) return null; // Si no hay caja seleccionada, no mostramos nada

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{color: '#fd6b0c'}}>Detalles de la Caja</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-detail">
          <p><strong>IdBox:</strong> {caja.idBox}</p>
          <p><strong>Empleado:</strong> {caja.empleado.name} {caja.empleado.lastName}</p>
          <p><strong>CUIT Empleado:</strong> {caja.empleado.cuit}</p>
          <p><strong>Email Empleado:</strong> {caja.empleado.email}</p>

          <hr />

          <h5 className="text-success">Detalles del Comercio</h5>
          <p><strong>Nombre Comercio:</strong> {caja.comercio.nameShop}</p>
          <p><strong>CUIT Comercio:</strong> {caja.comercio.cuit}</p>
          <p><strong>CVU Comercio:</strong> {caja.comercio.cvu}</p>
          <p><strong>Sucursal:</strong> {caja.comercio.branches.name}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CajaDetailsModal;
