import { useState, useEffect } from 'react';
import axios from 'axios';

export const useFetchEmpleados = (API_URL) => {
  const [empleados, setEmpleados] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const obtenerEmpleados = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');

      try {
        const response = await axios.post(
          `${API_URL}/public/v2/List/Employees`,
          {},
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            },
          }
        );
        if (isMounted) {
          const empleadosConEstado = response.data.msg.map((empleado) => ({
            ...empleado,
            isActive: empleado.state !== undefined ? empleado.state : false, 
            role: empleado.role || '',
          }));
          setEmpleados(empleadosConEstado);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.response?.data?.msg || 'Error desconocido.');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    obtenerEmpleados();

    return () => {
      isMounted = false;
    };
  }, [API_URL]);

  return { empleados, error, loading };
};
