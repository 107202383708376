import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './UploadForm.css';

function UploadForm({ setMessage, setError }) {
  const [file, setFile] = useState(null);
  const [amount, setAmount] = useState('');
  const [comprobante, setComprobante] = useState('');
  const [isUploading, setIsUploading] = useState(false); 
  const [fileKey, setFileKey] = useState(Date.now()); 
  const [isFormValid, setIsFormValid] = useState(false); 

  useEffect(() => {
    setIsFormValid(!!file && amount !== '' && comprobante !== '');
  }, [file, amount, comprobante]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;

    // Validar que el valor tenga máximo dos decimales
    const regex = /^\d+(\.\d{0,2})?$/;
    if (regex.test(value) || value === '') {
      setAmount(value);
    }
  };

  const handleComprobanteChange = (e) => {
    setComprobante(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !amount || !comprobante) {
      return; 
    }

    setIsUploading(true);

    const toBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });

    try {
      const base64File = await toBase64(file);
      const timestamp = new Date().toISOString();

      const data = {
        cuit: localStorage.getItem('cuit'),
        type: file.type === "application/pdf" ? "pdf" : "image",
        doc: base64File,
        timestamp: timestamp,
        amount: parseFloat(amount),
        tramite: comprobante
      };
      
      console.log('CUIT:', data.cuit);
      console.log('Tipo de archivo:', data.type);
      {/*console.log('Documento:', data.doc); */}
      console.log('Documento (primeros 100 caracteres):', data.doc.slice(0, 100));


      const response = await axios.post(`${process.env.REACT_APP_URL}/public/v1/Shops/File/Add`, data, {
        headers: {
          'Content-Type': 'application/json',
          'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token'),
          'page': 1,
        }
      });

      setMessage(response.data.msg);
      setError('');

      // Limpiar los campos del formulario
      setFile(null);
      setAmount('');
      setComprobante('');
      setFileKey(Date.now()); 
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg); 
      } else {
        setError('Error al cargar el archivo'); 
      }
      setMessage('');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formFile" className="mb-3" style={{ marginTop: '30px' }}>
        <Form.Label>Selecciona un archivo pdf o una imagen</Form.Label>
        <Form.Control 
          type="file" 
          accept=".pdf, image/*" 
          onChange={handleFileChange} 
          key={fileKey}
        />
      </Form.Group>
      <Form.Group controlId="formAmount" className="mb-3">
        <Form.Label>Monto</Form.Label>
        <Form.Control 
          type="text" 
          value={amount} 
          onChange={handleAmountChange} 
          placeholder="0.00" 
        />
      </Form.Group>
      <Form.Group controlId="formComprobante" className="mb-3">
        <Form.Label>N° comprobante</Form.Label>
        <Form.Control type="text" value={comprobante} onChange={handleComprobanteChange} />
      </Form.Group>
      <Button 
        variant="primary" 
        type="submit" 
        className={`ButtonsComprobante ${isUploading || !isFormValid ? 'disabled-button' : ''}`} 
        disabled={isUploading || !isFormValid} 
      >
        {isUploading ? 'Subiendo...' : 'Subir'}
      </Button>
    </Form>
  );
}

export default UploadForm;
